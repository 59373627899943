import React from "react";
import Animation from "../assets/Animation.gif";

const AudienceOwnership = () => {
  return (
    <main className="w-full relative text-white">
      <div className="px-[25px] md:px-10 xl:px-[120px]">
        <div className="w-full flex justify-center flex-col-reverse md:flex-row items-center gap-[20px] pt-[75px] md:pt-[81px] xl:pt-[222px]">
          <div className=" w-full xl:w-[47%] flex justify-start flex-col items-center">
            <h2 className="text-[30pt] md:text-[42px] 2xl:text-[55pt] text-wrap leading-[113%] xl:leading-[113%]  md:text-left san-variable flex w-full flex-col">
              <span>Audience Ownership</span>
            </h2>
            <div className="rubik-light md:text-[18pt] text-[15pt] leading-[110%] xl:leading-[120%]  md:text-left mx-auto text-gray-200 py-6">
              By leveraging advanced data analytics and actionable insights,
              artists can identify untapped opportunities and tailor their
              offerings to align seamlessly with their fanbase&apos;s desires.
            </div>
            <div className="rubik-light md:text-[18pt] text-[15pt] leading-[110%] xl:leading-[120%]  md:text-left mx-auto text-gray-200">
              These offerings can be communicated directly to fans via email &
              text through the platform, reducing your reliance on social media.
            </div>
          </div>
          <div className="w-full xl:w-[47%] flex justify-center items-center">
            <img
              src={Animation}
              alt="Social"
              className="2xl:h-[500px] 2xl:w-[500px] w-[250px] h-[250px] xl:w-[400px] xl:h-[400px] min-w-[250px] min-h-[250px]"
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default AudienceOwnership;
