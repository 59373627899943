import React from "react";
import logo from "../assets/Logo.png";
import Instagram from "../assets/icon/IG Logo.png";
import Twitter from "../assets/icon/twitter.svg";
import HeroImage from "../assets/Union.svg";
import ArrowIcon from "../assets/icon/Arrow.svg";
import Vector1 from "../assets/Vector1.svg";
import "../styles/animation.css";

const HeroSection = () => {
  return (
    <main className="w-full relative text-white">
      <div className="px-[25px] md:px-10 xl:px-[120px] pt-[30px] xl:pb-[30px]">
        <nav className="header flex justify-between items-center">
          <div>
            <img
              src={logo}
              alt="Campaign"
              className="w-[120px] h-[30px] md:w-[198px] md:h-[38px]"
              style={{
                objectFit: 'contain' // Ensures the image does not stretch or shrink              
              }}
            />
          </div>
          <div className="flex gap-2 items-center">
            <a href="https://twitter.com/CampaignLayer">
              <img
                src={Twitter}
                alt="Twitter"
                className="h-6 w-6 md:h-10 md:w-10 text-white"
              />
            </a>
            <a href="https://www.instagram.com/campaignlayer?igsh=MTZoZ2Q4ejhhNzBleg==">
              <img
                src={Instagram}
                alt="Instagram"
                className="h-6 w-6 md:h-10 md:w-10 text-white"
              />
            </a>
          </div>
        </nav>
        <div className="w-full flex justify-between md:flex-row flex-col items-start xl:items-start gap-5 xl:gap-[50px] 2xl:gap-[87px] pt-[75px] md:pt-[81px] xl:pt-40">
          <div className="w-full flex justify-center items-center xl:w-[48%]  2xl:w-[38%]">
            <img
              src={HeroImage}
              alt="HeroImage"
              className="w-[150px] h-[150px] sm:w-[237px] sm:h-[231px] md:min-w-[310px] md:min-h-[310px] xl:min-w-[478px] xl:min-h-[468px] animate-spin-slow "
            />
          </div>
          <div className="w-full xl:w-[52%] 2xl:w-[62%]">
            <div className="text-[35pt] md:text-[35pt] 2xl:text-[55pt] text-wrap leading-[113%] xl:leading-[113%] text-center md:text-left san-variable ">
              <span className="mr-3">
                Forging the future of fan engagement through
              </span>
              <p className="whitespace-nowrap inline-block"> data-driven </p>
              <span className="lg:ml-3">innovation</span>
            </div>
            <div className="rubik-light md:text-[18pt] text-[15pt] leading-[110%] xl:leading-[120%] text-center md:text-left mx-auto text-gray-200 mt-5">
              Campaign is a cutting edge platform designed to empower artists by
              harnessing the power of data, redefining the relationship between
              artists and their most devoted fans.
            </div>
            <div className="flex justify-center md:justify-start items-center">
              <a href="https://app.deform.cc/form/8bb8bae7-1ec8-4e40-99bf-d6c8a7dc7b0e">
                <button className="md:px-7 mt-5 md:py-4 px-5 py-2 bg-sky-blue text-black text-[18px] rounded-[50px] flex justify-between items-center gap-2">
                  <span className="san-variable-light">Book a demo</span>
                  <img
                    src={ArrowIcon}
                    alt="arrow"
                    className="w-4 h-3 text-black"
                  />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="gradient -z-10 h-fit">
        <img src={Vector1} alt="Vector1" />
      </div>
    </main>
  );
};

export default HeroSection;
