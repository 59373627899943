import React from "react";
import logo from "../assets/Logo.png";
import Instagram from "../assets/icon/IG Logo.png";
import Twitter from "../assets/icon/twitter.svg";
import { GoArrowUpRight } from "react-icons/go";

const Footer = () => {
  const ICON_SIZE = window.innerWidth <= 600 ? 10 : 20;
  return (
    <div className="flex rubik-light text-[10px] sm:text-[13px] xl:text-[15px] justify-center flex-col sm:justify-between sm:flex-row gap-3 sm:gap-1 xl:gap-4 flex-wrap lg:flex-nowrap items-center px-10 xl:mx-[120px] text-white pb-8 mt-[55px] sm:mt-[75px]">
      <div className="log flex">
        <img src={logo} alt="Campaign" className="w-[122px] h-[30px]" />
      </div>
      <div className="flex gap-2 items-center">
        <a href="https://twitter.com/CampaignLayer">
          <img
            src={Twitter}
            alt="Twitter"
            className="h-[21px] w-[21px] text-white"
          />
        </a>
        <a href="https://www.instagram.com/campaignlayer?igsh=MTZoZ2Q4ejhhNzBleg==">
          <img
            src={Instagram}
            alt="Instagram"
            className="h-[21px] w-[21px] text-white"
          />
        </a>
      </div>
      <div className="flex md:flex-col flex-rol">
        <div className="flex items-center gap-1 sm:gap-2">
          <span>Terms</span>
          <a href="https://app.campaignlayer.com/terms-of-use">
            <GoArrowUpRight size={ICON_SIZE} />
          </a>
        </div>
        <span className="inline ps-1 pe-1 sm:hidden"> | </span>
        <div className="flex items-center gap-1 sm:gap-2">
          <span>Privacy</span>
          <a href="https://app.campaignlayer.com/privacy-policy">
            <GoArrowUpRight size={ICON_SIZE} />
          </a>
        </div>
      </div>
      <div className="hidden md:inline">
        <div>Contact Support :</div>
        <div className="flex items-center gap-2">
          <span>Support@campaignlayer.com</span>
          <a href="mailto:support@campaignlayer.com" className="flex items-center gap-2">
            <GoArrowUpRight size={20} />
          </a>
        </div>
      </div>
      <p className="uppercase">&#169; 2024 Campaign Labs inc.</p>
    </div>
  );
};

export default Footer;
