import React from "react";
import Vector2 from "../assets/Vector2.svg";
import Social from "../assets/Container.png";

const SocialStreaming = () => {
  return (
    <main className="w-full relative text-white">
      <div className="px-[25px] md:px-10 xl:px-[120px]">
        <div className="w-full flex justify-center flex-col md:flex-row items-center gap-[3%] xl:gap-[6%] pt-[75px] md:pt-[81px] xl:pt-[222px]">
          <div className="w-full xl:w-[47%] flex justify-center items-center">
            <img
              src={Social}
              alt="Social"
              className="min-w-[300px] min-h-[300px]  max-h-[800px] xl:max-w-[700px] md:max-w-[350px] xl:h-full xl:w-full"
            />
          </div>
          <div className=" w-full xl:w-[47%] flex justify-start xl:justify-center flex-col items-center 2xl:pl-9">
            <div className="text-[30pt] md:text-[42px] 2xl:text-[55pt] text-wrap leading-[113%] xl:leading-[113%] md:text-left san-variable flex w-full flex-col">
              <span>Comprehensive Fan Data</span>
            </div>
            <div className="rubik-light md:text-[18pt] text-[15pt] leading-[110%] xl:leading-[120%]  md:text-left mx-auto text-gray-200 py-6 ">
              Campaign seamlessly integrates social media, streaming platforms,
              e-commerce sites, and real-world events, enabling top-tier data
              aggregation.
            </div>
            <div className="rubik-light md:text-[18pt] text-[15pt] leading-[110%] xl:leading-[120%]  md:text-left mx-auto text-gray-200 pb-5 ">
              AI-driven analysis offers a holistic understanding of fan
              interactions and behaviors, delivering precise insights for
              targeted engagement across diverse platforms.
            </div>
          </div>
        </div>
      </div>
      <img
        src={Vector2}
        alt="Vector2"
        className="gradient-social -z-10 h-fit "
      />
    </main>
  );
};

export default SocialStreaming;
