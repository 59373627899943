import React from "react";
import ArrowIcon from "../assets/icon/Arrow.svg";

const SustainableSection = () => {
  return (
    <div className="px-[25px] md:px-10 xl:px-[120px] pt-[75px] md:pt-[81px] xl:pt-[168px]">
      <h2 className="text-[24pt] md:text-[42px] 2xl:text-[50pt] text-wrap leading-[119%] xl:leading-[119%] text-center xl:w-[85%] xl:mx-auto san-variable-light font-semibold w-full">
        Create sustainable superfans through community cultivation
      </h2>
      <div className="md:mt-20 mt-8 flex justify-center items-center">
      <a href="https://app.deform.cc/form/8bb8bae7-1ec8-4e40-99bf-d6c8a7dc7b0e">
        <button className="md:px-7 mt-5 md:py-4 px-5 py-2 bg-sky-blue text-black text-[18px] rounded-[50px] flex justify-between items-center gap-2">
          <span className="san-variable-light">Book a demo</span>
          <img src={ArrowIcon} alt="arrow" className="w-4 h-3 text-black" />
        </button>
      </a>
      </div>
    </div>
  );
};

export default SustainableSection;
