import "./App.css";
import AudienceOwnership from "./components/AudienceOwnership";
import CommunityStream from "./components/CommunityStream";
import Footer from "./components/Footer";
import HeroSection from "./components/HeroSection";
import SocialStreaming from "./components/SocialStreaming";
import SustainableSection from "./components/SustainableSection";

function App() {
  return (
    <div>
      <HeroSection />
      <SocialStreaming />
      <AudienceOwnership />
      <CommunityStream />
      <SustainableSection />
      <Footer />
    </div>
  );
}

export default App;
