import React from "react";
import Image2 from "../assets/ALBUM-COVER-7.jpg";
import Image3 from "../assets/ALBUM-COVER-4.jpg";
import Image4 from "../assets/ALBUM-COVER-1.jpg";
import Image1 from "../assets/Music1.png";
import Image5 from "../assets/Music5.png";
import Vector3 from "../assets/Vector12.svg";
import Vector4 from "../assets/Vector13.svg";
import Image2Icon from "../assets/Album7Icon.png";
import Image3Icon from "../assets/Album4Icon.png";
import Image4Icon from "../assets/Album1Icon.png";

const CommunityStream = () => {
  return (
    <div className="relative overflow-hidden">
      <section className="flex flex-col-reverse md:flex-col md:pt-[81px] gap-0 md:gap-0 xl:pt-[222px]">
        <div className="px-[25px] md:px-10 xl:px-[120px] gradient-cover">
          <h2 className="text-[30pt] md:text-[42pt] 2xl:text-[55pt] text-wrap xl:text-center leading-[113%] xl:leading-[113%]  md:text-left san-variable flex justify-start md:justify-center flex-col items-start md:items-center">
            <span>Community-based Revenue Streams</span>
          </h2>
          <p className="text-left rubik-light md:text-[18pt] text-[15pt] xl:text-center leading-[110%] xl:leading-[120%] md: xl:w-[90%] 2xl:w-[80%] text-gray-200 xl:mx-auto mt-6 2xl:-pb-12 xl:pb-2">
            Whether it's curating personalized content, co-designing exclusive
            merchandise with fans, or creating once-in-a-lifetime experiences,
            Campaign equips you with the knowledge and tools to cultivate a
            loyal and engaged community that appreciates your unique artistic
            vision.
          </p>
        </div>
        <div className="gradient-stream-first h-fit">
          <img src={Vector3} alt="Vector3" />
        </div>
        <div className="gradient-stream-second h-fit">
          <img src={Vector4} alt="Vector4" />
        </div>
        <div />
        <div className="flex justify-between items-start gap-2 lg:gap-7 overflow-hidden relative z-5">
          <div className="xl:flex  hidden">
            <img
              src={Image1}
              alt={`Image`}
              className="h-[350px] 2xl:h-[450px] min-w-[80px]"
            />
          </div>
          <div className="relative lg:mt-[72px] -ml-20 md:-ml-14 xl:-ml-0 md:mt-[75px] mt-[55px] ">
            <img
              src={Image2}
              alt={`Image`}
              className="images-style h-[200px] w-[200px] lsm:w-[250px] lsm:h-[250px] md:h-[300px] md:w-[300px] lg:w-[400px] lg:h-[400px] xl:h-[350px] xl:w-[350px] 2xl:h-[450px] 2xl:w-[450px] 2xl:max-h-[450px] 2xl:max-w-[450px] max-h-[180px] max-w-[180px]"
            />
            <img
              src={Image2Icon}
              alt={`Image`}
              className="absolute -bottom-[14%] md:-bottom-[14%] z-[1000]  xl:-bottom-[14%] right-0 left-0 mx-auto h-[77px] w-[225px] lg:h-auto lg:w-auto"
            />
          </div>
          <div className="relative md:mt-[150px] mt-[100px] lg:mt-[143px] mb-20">
            <img
              src={Image3}
              alt={`Image`}
              className="images-style h-[200px] w-[200px] md:h-[300px] lsm:w-[250px] lsm:h-[250px] md:w-[300px] lg:w-[400px] lg:h-[400px] xl:h-[350px] xl:w-[350px] 2xl:h-[450px] 2xl:w-[450px] 2xl:max-h-[450px] 2xl:max-w-[450px] max-h-[180px] max-w-[180px]"
            />
            <img
              src={Image3Icon}
              alt={`Image`}
              className="absolute -bottom-[14%] md:-bottom-[14%] z-[1000]  xl:-bottom-[14%] right-0 left-0 mx-auto h-[77px] w-[225px] lg:h-auto lg:w-auto"
            />
          </div>
          <div className="relative lg:mt-[72px] -mr-20 md:-mr-14 xl:-mr-0 md:mt-[75px] mt-[55px] ">
            <img
              src={Image4}
              alt={`Image`}
              className="images-style h-[200px] w-[200px] lsm:w-[250px] lsm:h-[250px] md:h-[300px] md:w-[300px] lg:w-[400px] xl:h-[350px] xl:w-[350px] lg:h-[400px] 2xl:h-[450px] 2xl:w-[450px] 2xl:max-h-[450px] 2xl:max-w-[450px] max-h-[180px] max-w-[180px]"
            />
            <img
              src={Image4Icon}
              alt={`Image`}
              className="absolute -bottom-[14%] md:-bottom-[14%] z-[1000]  xl:-bottom-[14%] right-0 left-0 mx-auto h-[77px] w-[225px] lg:h-auto lg:w-auto"
            />
          </div>
          <div className="xl:flex  hidden">
            <img
              src={Image5}
              alt={`Image`}
              className="h-[350px] 2xl:h-[450px] min-w-[80px]"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default CommunityStream;
